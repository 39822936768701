<template>
     <div class="header" >
        <a>{{headerText}}</a>
        <div class="nav_icon" @click="showProduction()">
              <font-awesome-icon icon="list" />
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TopBar',

    data() {
        return {
          headerText:'Seleccionar finca',
        };
    },

    computed: {
      
    },

    watch: {},

    beforeMount() {
    
    },

    mounted() {
      this.$root.$on('change', (type) => {
        this.changeText(type);
      });

      this.$root.$on('list', (type) => {
        this.changeText(type);
      });

      this.$root.$on('quantity', (type) => {
        this.changeText(type);
      });
    },
    
    methods: {
      changeText(type)
      {
        if(type == 1)
        {
          this.headerText = 'Seleccionar finca';
        }

        else if (type == 2)
        {
          this.headerText = 'Seleccionar formato';
        }

        else if (type == 3)
        {
          this.headerText = 'Introducir cantidad';
        }

        else
        {
          this.headerText = 'Producción de hoy';
        }
      },

      showProduction()
      {
        this.$root.$emit('list', 4);
      }
    }
  }
  </script>
