<template>
    <section>
        <top-bar></top-bar>
        <main class="site-main">
            <list-farmlands   :style="{'display' : displayFarmlands}"></list-farmlands>
            <list-formats     :style="{'display' : displayFormats}"></list-formats>
            <quantity         :style="{'display' : displayQuantity}"></quantity>
            <list-productions :style="{'display' : displayProductions}"></list-productions>
        </main>
        <bottom-bar></bottom-bar>
  </section>
</template>
  
<script>
import TopBar from '../widgets/TopBar.vue';
import ListFarmlands from '../widgets/ListFarmlands.vue'
import ListFormats from '../widgets/ListFormats.vue'
import QuantityWidget from '../widgets/QuantityWidget.vue'
import ListProductions from '../widgets/ListProductions.vue'
import BottomBar from '../widgets/BottomBar.vue';

export default {
    name: 'ProductionView',

    components: {
        'top-bar': TopBar,
        'list-farmlands': ListFarmlands,
        'list-formats': ListFormats,
        'quantity': QuantityWidget,
        'list-productions': ListProductions,
        'bottom-bar': BottomBar
    },

    data() {
        return {
            displayFarmlands: 'true',
            displayFormats: 'none',
            displayQuantity: 'none',
            displayProductions: 'none',
        };
    },

    computed: {
      
    },

    watch: {},

    beforeMount() {
    
    },

    mounted() {
        this.$root.$on('change', (type) => {
            this.changeView(type);
        });

        this.$root.$on('list', (type) => {
            this.changeView(type);
        });
    },
    methods: {
        changeView(type)
        {
            if(type == 1)
            {
                this.displayFarmlands = 'inherit';
                this.displayFormats = 'none';
                this.displayQuantity = 'none';
                this.displayProductions = 'none';
            }

            else if (type == 2)
            {
                this.displayFarmlands = 'none';
                this.displayFormats = 'inherit';
                this.displayQuantity = 'none';
                this.displayProductions = 'none';
            }

            else if (type == 3)
            {
                this.displayFarmlands = 'none';
                this.displayFormats = 'none';
                this.displayQuantity = 'inherit';
                this.displayProductions = 'none';
            }

            else
            {
                this.displayFarmlands = 'none';
                this.displayFormats = 'none';
                this.displayQuantity = 'none';
                this.displayProductions = 'inherit';
            }
        }
    }
};

</script>