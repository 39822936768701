<template>
     <div class="list" >
      <div v-for="(format, index) of formats" :key="index">
        <div v-if="isDisplay(0,index)">
          <div class="row">
            <div class="column" @click="selectItem(0,index)">
              <div class="row">
                <div class="colour" :style="{ 'background-color' : getArrayData(0,index,0) }"></div>
                <h3>{{ getArrayData(0,index,1)  }}</h3>
              </div>
            </div>
            <div v-if="isDisplay(1,index)">
              <div class="column" @click="selectItem(1,index)">
                <div class="row">
                  <div class="colour" :style="{ 'background-color' : getArrayData(1,index,0) }"></div>
                  <h3>{{ getArrayData(1,index,1)  }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ListFormats',

    data() {
        return {
          formats: [],
          calc_index: 0,
          length:0,
        };
    },

    computed: {
      
    },

    watch: {

    },

    beforeMount() {

    },

    mounted() {
      this.$root.$on('farmland', (farmland) => {
        console.log("farmland");
        console.log(farmland);
        this.formats = farmland.formats
        this.length = this.formats.length;
      });

      this.$root.$on('change', (type) => {
        if(type == 1)
        {
          this.removeData(type);
        }
      });

      this.$root.$on('quantity', (type) => {
        this.removeData(type);
      });
        
    },

    methods: {

      isDisplay(i,index)
      {
        let final_index;
        let bool;
        bool = true;
        if (index === 0)
        {
          final_index = index + i;
        }

        else
        {
          final_index =  index + i + 1;

          if(final_index > (this.formats.length - 1))
          {
            final_index = 0;
            bool = false;
          }
        }

        return bool;
      },
     
      getIndex(i,index)
      {
        let final_index;
        if (index === 0)
        {
          final_index = index + i;
        }

        else
        {
          final_index =  index + i + 1;

          if(final_index > (this.formats.length - 1))
          {
            final_index = 0;
          }
        }

        return final_index;
      },

      getArrayData(i,index,type)
      {
        let final_index;

        final_index = this.getIndex(i,index);

        if (type === 0)
        {
          return this.formats[final_index].colour;
        }

        else
        {
          return this.formats[final_index].name;
        }
      },

      removeData(type)
      {
        if(type != 2)
        {
          this.formats = [];
          this.length = 0;
        }
      },

      selectItem(i,index)
      {
        let final_index;
        final_index = this.getIndex(i,index);
        console.log(this.formats[final_index]);
        this.$root.$emit('format', this.formats[final_index]);
      },
    }
  }
  </script>
