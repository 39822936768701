<template>
     <div class="quantity" >
      <div class="displayCalc">
        <div class="number">
            <a>{{ quantity }}</a>
        </div>
      </div>
      <div class="row">
        <div class="column" @click="onclickNumber(7)">
          <div class="number">
            <a>7</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(8)">
          <div class="number">
            <a>8</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(9)">
          <div class="number">
            <a>9</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column" @click="onclickNumber(4)">
          <div class="number">
            <a>4</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(5)">
          <div class="number">
            <a>5</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(6)">
          <div class="number">
            <a>6</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column" @click="onclickNumber(1)">
          <div class="number">
            <a>1</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(2)">
          <div class="number">
            <a>2</a>
          </div>
        </div>
        <div class="column" @click="onclickNumber(3)">
          <div class="number">
            <a>3</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column" @click="onclickNumber(0)">
          <div class="number">
            <a>0</a>
          </div>
        </div>
        <div class="column ac" @click="onClickReset()">
          <div class="number">
            <a>AC</a>
          </div>
        </div>
        <div class="column ok" @click="onClickSave()">
          <div class="number">
            <a>OK</a>
          </div>
        </div>
      </div>
      <dialog aria-labelledby="dialog_title" aria-describedby="dialog_description">
        <h2>Guardar producción</h2>
        <p>Se va a proceder a guardar los siguientes datos:<br><br>
            - Finca: <strong>{{ farmland.name }}<br></strong>
            - Formato: <strong>{{ format.name }}<br></strong>
            - Cantidad: <strong>{{ quantity }}<br><br></strong>
        </p>
        <div class="flex flex-space-between">
          <a class="cancel" @click="dialogCancel()">Cancelar</a>
          <a class="ok" @click="dialogConfirm()">Confirmar</a>
        </div>
      </dialog>
    </div>
  </template>
  
  <script>

  export default {
    name: 'QuantityWidget',

    data() {
        return {
          farmland: {},
          format: {},
          quantity:0,
          lengthQ:0,
          type:0,
          dialog:null,
          dialogText:"",
        };
    },

    computed: {
      
    },

    watch: {

    },

    beforeMount() {

    },

    mounted() {

      this.$root.$on('farmland', (farmland) => {
        this.farmland = farmland;
      });

      this.$root.$on('format', (format) => {
        this.format = format;
      });

      this.$root.$on('change', (type) => {
        this.onClickReset();
        this.type = type;
      });

      this.dialog = document.querySelector("dialog");
        
    },

    methods: {
     onclickNumber(id)
     {
      if(this.lengthQ == 5)
      {
        this.onClickReset();
      }
      this.quantity = id + (this.quantity * 10); 
      this.lengthQ = this.lengthQ + 1;
     },

     onClickReset()
     {
        this.quantity = 0;
        this.lengthQ = 0;
     },

     onClickSave()
     {
        this.dialog.showModal();
     },

     dialogConfirm()
     {
        this.saveData();
        this.dialog.close();
        this.quantity = 0;
        this.lengthQ = 0;
        this.$root.$emit('quantity', 1);
     },
     
     dialogCancel()
     {
        this.dialog.close();
     },

     async saveData()
      {
        try
        {
            console.log(this.farmland);
            console.log(this.format);
            const response = await this.$http.post(this.$hostname+'/data/', {
                farmland_id: this.farmland.id,
                format_id: this.format.id,
                units: this.quantity
            });
            console.log(response);
        }catch(error){
          console.log(error);
        }
      },
    }
  }
  </script>
