<template>
  <div class="production" >
    <vuetable ref="vuetable"
        :api-mode="false"
        :fields="fields"
        :per-page="perPage"
        :data-manager="dataManager"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
    ></vuetable>
    <div style="padding-top:10px">
        <vuetable-pagination ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
    </div>
 </div>
</template>

  
  <script>
  import Vuetable from 'vuetable-2'
  import VuetablePagination from "vuetable-2/src/components/VuetablePagination";

  export default {
    name: 'ListProductions',

    components: {
      Vuetable,
      VuetablePagination
    },

    data() {
        return {
          fields: [
          {
            name: 'farmland',
            title: 'Finca'
          },
          {
            name: 'format',
            title: 'Formato'
          },
          {
            name: 'units',
            title: 'Unidades'
          },
          {
            name: 'created_on',
            title: 'Hora'
          }
        ],
          perPage: 20,
          data: []
        };
    },

    computed: {
      
    },

    watch: {
      data(newVal, oldVal) {
        console.log(newVal);
        console.log(oldVal);
        this.$refs.vuetable.refresh();
      }
    },

    beforeMount() {

      this.getProduction();
      
    },

    mounted() {
    
      this.$root.$on('list', () => {
        this.getProduction();
      });

    },

    methods: {
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
      },

      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      dataManager(sortOrder, pagination) {
        if (this.data.length < 1) return;

        let local = this.data;

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
        );
      
        return {
          pagination: pagination,
          data: local
        };
      },

      async getProduction()
      {
        try
        {
          const response = await this.$http.get(this.$hostname+'/production/daily');
          this.data = response.data;
        }catch(error){
          console.log(error);
        }
      }
    }
  }
  </script>
<style>
/* .vuetable-th-checkbox-id {
  width: 2%;
}
.vuetable-th-id {
  width: 8%;
} */

.vuetable-body-wrapper {
  background-color: #dce6df;
}

.vuetable-body
{
  background-color: #FFF;
}
.vuetable-th-farmland {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 5%;
}
.vuetable-th-format {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 5%;
}
.vuetable-th-units {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 5%;
}
.vuetable-th-created_on {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 5%;
}
.vuetable-td-farmland {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 3%;
}
.vuetable-td-format {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 3%;
}
.vuetable-td-units {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 3%;
}
.vuetable-td-created_on {
  width: 15%;
  padding-bottom: 3%;
  padding-top: 3%;
}
.vuetable-th-slot-roles {
  width: 65%;
}
.vuetable-pagination nav {
  float: right;
}
.vuetable-pagination-dropdown nav {
  float: right;
}
</style>