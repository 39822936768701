<template>
  <div class="list" >
   <div v-for="(farmland, index) of farmlands" :key="index">
     <div v-if="isDisplay(0,index)">
       <div class="row">
         <div class="column" @click="selectItem(0,index)">
           <div class="row">
             <div class="colour" :style="{ 'background-color' : getArrayData(0,index,0) }"></div>
             <h3>{{ getArrayData(0,index,1) }}</h3>
           </div>
         </div>
         <div v-if="isDisplay(1,index)">
           <div class="column" @click="selectItem(1,index)">
             <div class="row">
               <div class="colour" :style="{ 'background-color' : getArrayData(1,index,0) }"></div>
               <h3>{{ getArrayData(1,index,1) }}</h3>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

  
  <script>
  export default {
    name: 'ListFarmlands',

    data() {
        return {

          farmlands: [],
          length:0,
        };
    },

    computed: {
      
    },

    watch: {

    },

    beforeMount() {
      this.getData();
    },

    mounted() {

      this.$root.$on('change', (type) => {
        if(type===1)
        {
          this.getData();
        }
      });

      this.$root.$on('quantity', (type) => {
        if(type===1)
        {
          this.getData();
        }
      });
        
    },

    methods: {

      isDisplay(i,index)
      {
        let final_index;
        let bool;
        bool = true;
        if (index === 0)
        {
          final_index = index + i;
        }

        else
        {
          final_index =  index + i + 1;

          if(final_index > (this.farmlands.length - 1))
          {
            final_index = 0;
            bool = false;
          }
        }

        return bool;
      },

      getIndex(i,index)
      {
        let final_index;
        if (index === 0)
        {
          final_index = index + i;
        }

        else
        {
          final_index =  index + i + 1;

          if(final_index > (this.farmlands.length - 1))
          {
            final_index = 0;
          }
        }

        return final_index;
      },

      getArrayData(i,index,type)
      {
        let final_index;

        final_index = this.getIndex(i,index);
        if (type === 0)
        {
          return this.farmlands[final_index].colour;
        }
          

        else
        {
          return this.farmlands[final_index].name;
        }
      },

      selectItem(i,index)
      {
        let final_index;
        final_index = this.getIndex(i,index);
        console.log(this.farmlands[final_index]);
        this.$root.$emit('farmland', this.farmlands[final_index]);
      },

      async getData()
      {
        try
        {
          const response = await this.$http.get(this.$hostname+'/data/');
          this.farmlands = response.data;
          this.length = this.farmlands.length;
        }catch(error){
          console.log(error);
        }
      },
     
    }
  }
  </script>
